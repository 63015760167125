import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon } from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import Logo from "src/components/Logo";
import Account from "./Account";
import Notifications from "./Notifications";
import RequestCampaign from "./RequestCampaign";

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: "none",
		backgroundColor: theme.palette.primary.main
	}
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
	const classes = useStyles();

	return (
		<AppBar className={clsx(classes.root, className)} {...rest}>
			<Toolbar className={classes.toolbar}>
				<Hidden lgUp>
					<IconButton className={classes.menuButton} color="inherit" onClick={onMobileNavOpen}>
						<SvgIcon fontSize="small">
							<MenuIcon />
						</SvgIcon>
					</IconButton>
				</Hidden>
				<Hidden mdDown>
					<RouterLink to="/app">
						<Logo />
					</RouterLink>
				</Hidden>
				<Box ml={2} flexGrow={1} />
				<Hidden xsDown>
					<Box ml={2}>
						<RequestCampaign />
					</Box>
				</Hidden>
				<Box ml={2}>
					<Notifications />
				</Box>
				<Box ml={2}>
					<Account />
				</Box>
			</Toolbar>
		</AppBar>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func
};

export default TopBar;
