import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  requestBtn: {
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    }
  }
}));

function RequestCampaign() {
  const classes = useStyles();
  return (
    <Button variant="contained" className={classes.requestBtn}>
      <a
        href="mailto:campaigns@gust.io"
        rel="noopener noreferrer"
        target="_top"
      >
        request new campaign
      </a>
    </Button>
  );
}

export default RequestCampaign;
