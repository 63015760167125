import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from 'src/serviceWorker';
import { configureStore } from 'src/store';
import App from 'src/App';

enableES5();

const store = configureStore();

Sentry.init({
  dsn:
    'https://39587ecabae74182af8c881fec74988e@o412754.ingest.sentry.io/5566070',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()]
});

ReactDOM.render(
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback="An error has occurred">
      <App />
    </Sentry.ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
